.home {
  display: flex;
}
.sidebar {
    min-width: 250px;
    background-color:white;
    box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
    height: 100vh;
    position: sticky;
    top: 0;
    overflow-y: auto;
}
.sidebar-tab {
    padding:15px 10px;
    cursor: pointer;
    font-weight: bolder;
    border-bottom: 1px solid rgba(0,0,0,.1);
    transition: .2s ease;
}
.sidebar-tab:hover {
    background-color: var(--Secondry_color);
    color:white;
}
.selected-tab {
    background-color: var(--Secondry_color);
    color:white;
}
.main {
    padding:20px;
    background-color: #fafbff;
    margin:0 auto;
    width: 100%;
    min-height: 100vh;
}
.list {
    margin: 60px 0 50px 0;
    border-top: 1px solid rgba(0,0,0,.2);

}
.list-items {
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
    display:flex;
    padding:10px;
    margin-top: 20px;
    background-color:white;
    justify-content: space-between;
    align-items: center;
}
.list-items video {
    width:200px
}
.list-items-img {
    padding:5px;
    width:10%;
}
.list-items-img img {
    width:40px;
    padding:2px;
    border:1px solid rgba(0,0,0,.2)
}
.list-items-title {
    width:20%;
    padding:10px;
    text-align:center
}
.list-items-description {
    width:50%;
    padding:10px;
    text-align: justify;
}
.list-items-option  {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: center;
}
.list-items-option img {
    width:15px;
    cursor: pointer;
    margin: 0 10px;
}
.modal-continue {
    width:100%;
    height: 100%;
    background-color:rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000000000;
}
.modal-continue-box {
    padding:10px;
    border-radius: 5px;
    min-height: 100px;
    min-width: 250px;
    background-color:white;
    transition:.5s ease
}

.modal-continue-box > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
}
.modal-continue-box > div > div {
   cursor:pointer
}
.line {
    border-bottom:2px solid rgba(0,0,0,.4);
    padding:0 0 10px 0;
    margin:0 0 20px 0;
    font-weight: bolder;
    color:red
}
.email-list-item {
    flex-direction: column;
    display: flex;
    line-height: 35px;
}

.email-list-item tr td > div  {
  display: flex;
    align-items: center;
    justify-content: center;
}

.email-list-item tr td > div span  {
  margin: 0 10px;
}

.email-list-item table {
   width: 100%;
    text-align: center;
    margin-bottom: 100px;
}
.email-list-item table td , .email-list-item table th {
  border: 1px solid rgba(0,0,0,.2);
}
.email-list-item table th {
    background: #10b7cd;
    color: white;
}
.email-list-item span {
    margin-right: 5px;
    color:green;
}
.create-new-menu {
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0,0,0,.1);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.create-new-menu-added {
    margin-top: 40px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0,0,0,.1);
    position: sticky;
    top: 0;
    display: flex;
    align-items: start;
    justify-content: right;
    padding: 20px;
    flex-direction: column;
}

.create-new-menu input {
    outline: none;
    border: 1px solid rgba(0,0,0,.2);
    padding: 5px;
    border-radius: 5px;
    min-width: 250px;
}
.create-new-menu-add ,.create-new-menu-remove {
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin: 0 10px 0 0;
    cursor: pointer;
    min-width: 55px;
    text-align: center;
}
.create-new-menu-add {
    background: #2e804a;
}
.create-new-menu-remove {
    background: #c92a2a;
}

.tree_view {
    width: 250px;
}
.tree_box li {
    display: flex;
}
.tree_box_title {
    width: 250px;
    background-color: #e8e8e8;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tree_children {
    position: absolute;
    right: 250px;
    display: flex;
    flex-direction: column;
    z-index: 200;
    top: 0;
}

.tree_box_title:hover {
    background-color: #10B245;
    color: white;
}


.menu_modal {
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 112;
    justify-content: center;
    align-items: center;
    opacity: 0;
    display: flex;
    max-height: 0;

}
.open_menu {
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 112;
    align-items: center;
    opacity: 1;
    transition: .3s ease-in-out;
    display: flex;
    height: 100%;
}
.menu_box {
    max-width: 1280px;
    background-color: white;
    cursor: default;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    height:100%

}
.open_box {
    transition: .3s ease-in-out;
    transform: translateX(0);
}
.menu_logo {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0,.2);
    margin-bottom: 20px;
}
.menu_box_close {
    max-height: 0;
    transition: .3s ease-in-out;
    transform: translateX(100%);
}
.menu_box > img {
    bottom: 0;
    max-width: 400px;
    max-height: 300px;
    position: absolute;
    left: 0;
}

.tree_box li {
    display: flex;
}

.tree_box_title {
    width: 250px;
    background-color: #e8e8e8;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tree_children {
    position: absolute;
    right: 250px;
    display: flex;
    flex-direction: column;
    z-index: 200;
    top: 0;
}

.tree_box_title:hover {
    background-color: #10B245;
    color: white;
}


.tree_view {
    width: 250px;
    position: relative;
}

.tree_view img {
    position: absolute;
    width: 20px;
    margin-top: 7px;
    display: flex;

}

.tree_box_title {
    margin-right: 20px;
    background-color: unset;
    padding: 5px
}

.tree_box_title:hover {
    background-color: unset;
    color: gray;
    align-items:center;
    display:flex
}

.tree_children {
    position: unset;
    margin-right: 15px;
}

.tree_box li {
    display: unset;
}
.tree_box_title .radio-category {
    margin:0 2px 0 5px;
}
.modal-category {
    position:fixed;
    width:300px;
    background:rgba(255,255,255,1);
    top: 0;
    left: 0;
    overflow-y: scroll;
    padding: 50px;
    transition: .3s ease-in-out;
}
.show-category-modal {
    opacity:1;
    height:calc(100% - 100px);
    z-index: 1000000;
}
.hide-category-modal {
    height:0;
    opacity:0;
    z-index: -1111111;
}
.category-btn-section {
    display:flex;
    justify-content: center;
    width: 100%;
    margin-top:50px
}
.category-btn {
    margin:0 0 30px 0;
    padding:5px;
    border-radius: 5px;
    color: white;
    width:160px;
    text-align: center;
    cursor: pointer;
    background-color: #ff00ae;

}

.red {
    background-color: #ff00ae;
}

.blue {
    background-color: #0077ff;
    margin-left: 10px;
}
.drop-down-tab {
    overflow-y: hidden;
    border-radius: 5px;
    background: #def5e2;
    color: black;
    padding: 10px;
    margin: 10px;
    width: 80%;
    border: 1px solid black;
    transition: .3s ease-in-out;
    font-weight: bolder;
}
.drop-down-tab-title {
    margin-bottom: 20px;
    height: 25px;
    cursor: pointer;

}
.show-drop {
    max-height: 2000px;
}
.hide-drop {
    max-height: 25px;
}
.main-text-section {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.main-text-section-title {
    display: flex;
    align-items: center;
    width: 100%;
}
.main-text-section-title input {
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 80%;
    border: 1px solid rgba(0,0,0,.4);
}

.add-main-text {
    background: #c92a2a;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
.text-list {
    text-align: right;
    width: 97%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}
.text-list span:last-child{
   color: #c92a2a;
    cursor: pointer;
}

.pagination {
    width:100%;
    margin-top: 20px;
}
.pagination .active {
    background-color: var(--Tertiary_color);
}
.pagination ul {
    width:100%;
    display: flex;
    justify-content:center
}
.pagination ul li {
    padding:5px 10px;
    min-width: 15px;
    margin:2px;
    background-color:var(--Primary_color);
    border-radius: 5px;
    text-align: center;
    cursor: pointer
}
.pagination ul li  a{
    color:white;
    text-decoration: none;
}



