.login {
    height:100vh;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(34,83,195);
    background: linear-gradient(0deg, rgba(34,83,195,1) 0%, rgba(255,255,255,1) 48%, rgba(255,0,0,1) 100%);

}
.login-box {
    background-color:transparent;
    border: 1px solid rgba(0,0,0,.2);
    padding:40px;
    border-radius: 5px;
    text-align:center;
    direction: rtl;

}
.login-form {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    justify-content: space-evenly;

}
.MuiOutlinedInput-inputMarginDense::placeholder{
    direction: rtl;
}