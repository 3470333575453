.input {
   display: flex;
   direction: rtl;
   align-items: flex-start;
   flex-direction: column;
   margin-bottom: 50px;
   width: 100%;
}
.input input {
   outline: none!important;
   border-radius: 5px;
   border:1px solid rgba(0,0,0,.2);
   background-color:white;
   padding:10px;
   margin:10px;
   width: 80%;
}
.input  label {
   font-weight: bolder;
}
.input input::placeholder {
   opacity: .5;
}