.image-uploader {
    position: relative;
    width: 150px;
    height: 150px;
    padding: 10px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 5px;
}

.image-uploader img , .image-uploader video {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .3;
    z-index: 0;
    top: 0;
}

.image-uploader input {
    position: absolute;
    right: -999999px
}

.image-uploader label {
    font-weight: bolder;
    margin-bottom: 50px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1111;
    font-size: 1rem;
}