.text-area {
    display:flex;
    direction:rtl;
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
}
.text-area textarea {
    outline: none!important;
    border-radius: 5px;
    border:1px solid rgba(0,0,0,.2);
    background-color:white;
    padding:10px;
    margin:10px;
    min-width: 400px;
    min-height: 150px;
    width:80%
}
.text-area label {
    font-weight: bolder;
}
.text-area textarea::placeholder {
    opacity: .5;
}