:root {
    --Primary_color: rgb(27 46 68);
    --Secondry_color: #6781bd;
    --Tertiary_color: red;
    --Quaternary_color: rgb(250, 250, 250);
    --Quinary_color: rgb(0,56,122);
    --Senary_color: #fff;
    --Text_color: #767676;
    --border_color: #99999973
}

*::-webkit-scrollbar {
    width: 5px;
}
/* Track */
*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray;
    border-radius: 10px;
}
/* Handle */
*::-webkit-scrollbar-thumb {
    background: var(--Primary_color);
    border-radius: 10px;
}
h1 {
    font-size: 1.8rem!important;
    margin:0!important
}

h2 {
    font-size: 1.3rem!important;
    margin:0!important

}

h3 {
    font-size: 1.2rem!important;
    margin:0!important

}

h4 {
    font-size: 1rem!important;
    margin:0!important

}
p , span , a {
    margin:0
}
h1,h2,h3,h4,h5 {
    font-weight: bolder;
}
@font-face {
    font-family: IRAN-Sans;
    src: url(res/fonts/vazir-font-v18.0.0/Farsi-Digits/Vazir-FD.ttf);
}
body, input {
    background-color: white;
    font-size: 14px;
    direction: rtl;
    margin: 0;
    padding: 0;
    font-family: IRAN-Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input::placeholder {
    font-family: IRAN-Sans;
}
textarea::placeholder {
    font-family: IRAN-Sans;
}
textarea {
    font-family: IRAN-Sans;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

ul {
    padding:0;
    margin:0;
}
li {
    text-decoration: none;
    list-style: none;
}
.container {
    padding:0 2.5%;
    max-width: 1280px;
    margin:0 auto;
}

.btn {
    border-radius: 5px;
    color:white;
    padding:3px 10px;
}
.success {
    background-color:darkgreen;
}
.danger {
    background-color:red
}
.show {
    opacity:1;
    height:100%;
    width:100%;
}

.hidden {
    opacity:0;
    height:0;
    width:0;
    right:-100%;
}




