.bottom {
    background-color: #1e2f6b;
    border-radius: 5px;
    color:white;
    max-width: 100px;
    padding:5px;
    text-align: center;
    cursor:pointer;
    margin: 20px;
}
.loading {
    pointer-events: none;
    opacity:.5
}

